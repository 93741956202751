import React, { useEffect } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import './Guideline.css';

function Guideline() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <Header />
            <div className="guideline-container">
                <h1 style={{ textAlign: 'center', fontSize: '28px'}}>友達さんの案内</h1>
                <p>
                    私たちは「友達さん」を、日本語とベトナム語が好きな人たちをつなげるために作りました。ここでは、参加者が出会って会話を楽しんだり、自分のストーリーを共有したりすることができます。この活動を通して、外国語をもっと楽しく学べるようになると信じています。そして、みんなが助け合い、学び合える絆を作る場を目指しています。
                </p>
                <hr />
                <h2>活動概要</h2>
                <ul>
                    <li><strong>開始時期</strong>: 2024年初頭</li>
                    <li><strong>開催回数</strong>: 2025年1月15日時点で44回</li>
                    <li><strong>参加者数</strong>: 200人以上
                        <ul>
                            <li>ベトナム人: 約2/3（平均年齢29歳）</li>
                            <li>日本人: 約1/3（平均年齢48歳）</li>
                        </ul>
                    </li>
                </ul>
                <hr />
                <h2>活動の仕組み</h2>
                <ul>
                    <li>開催日: 毎週日曜日</li>
                    <li>時間: 1時間30分
                        <ul>
                            <li>21:00~22:30 (日本時間)</li>
                            <li>19:00~20:30 (ベトナム時間)</li>
                        </ul>
                    </li>
                    <li>セッション構成:
                        <ul>
                            <li><strong>セッション1</strong>: 21:00 ~ 21:30</li>
                            <li><strong>セッション2</strong>: 21:30 ~ 22:00</li>
                            <li><strong>セッション3</strong>: 22:00 ~ 22:30</li>
                        </ul>
                    </li>
                </ul>
                <p>
                    各セッションには、5分間の準備時間と25分間の会話時間が含まれます。途中参加の場合、次のセッション開始5分前までにご参加ください。日本人とベトナム人のペアのクラスを優先的に作ります。友達さんのアカウントを持っていれば、事前登録なしで自由に参加できます。
                </p>
                <hr />
                <h2>参加を成功させるコツ</h2>
                <h3>必須条件</h3>
                <ul>
                    <li><strong>日本語能力</strong>: 中級レベル(N3以上)で日本語を話すスキルが必要です。</li>
                    <li><strong>カメラの使用</strong>: エベントには必ずカメラをオンにしてください。</li>
                </ul>
                <h3>準備のヒント</h3>
                <ol>
                    <li><strong>時間を守る</strong>: セッション開始の5分前にご参加してください。</li>
                    <li><strong>ネット環境の確認</strong>: 安定したインターネット接続を用意してください。</li>
                    <li><strong>パソコンから参加</strong>: 最新のChromeブラウザを使用してパソコンからご参加ください。スマホやタブレットよりパソコンの方が安定します。</li>
                    <li><strong>礼儀を大切にする</strong>: 他の参加者に対して思いやりを持ち、丁寧に接してください。</li>
                </ol>
                <hr />
                <h2>禁止事項</h2>
                <ol>
                    <li>イベント中に個人的な連絡先やプライベートな情報を聞かないでください。</li>
                    <li>宗教、政治、ネットワークビジネス、またはその他のイベントへの勧誘を目的とした参加は禁止します。</li>
                    <li>他の参加者に対して失礼な態度や迷惑行為を行わないでください。</li>
                    <li>許可なく録音や録画を行うことは禁止されています。</li>
                </ol>
                <p> このルールに違反する行為があった場合、主催者へご連絡ください。該当者の参加を制限する場合があります。</p>
            </div>
            <button className="back-to-home-button" onClick={() => window.location.href = '/'}>
                戻る
            </button>
            <Footer />
        </div>
    );
}

export default Guideline;
