import { useEffect } from "react";
import "./Meeting.css";
import { ZoomMtg } from "@zoom/meetingsdk";
import { Amplify } from "aws-amplify";
import awsconfig from "../../aws-exports";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { fetchAuthSession } from "@aws-amplify/auth";
import { useState } from "react";
import axios from "axios";
import { ClipLoader } from "react-spinners"; // Import ClipLoader
import "./ChangeBreakRoom/ChangeBreakRoom"
import ChangeBreakRoom from "./ChangeBreakRoom/ChangeBreakRoom";
import MatchedHistory from './MatchedHistory/MatchedHistory';
import log from "loglevel";

// Set the log level using the environment variable
log.setLevel('info');

// Configure Amplify with AWS settings
Amplify.configure(awsconfig);
const apiDomain = awsconfig.aws_cloud_logic_custom[0].endpoint;
const leave_Url = awsconfig.oauth.redirectSignIn;

function Meeting() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const userInfo = useSelector((state) => state.user.userInfo);
  const [loading, setLoading] = useState(false); // Add loading state
  const [canStartMeeting, setCanStartMeeting] = useState(false); // New state to control meeting start

  const [attendees, setAttendees] = useState([]);
  const [pairs, setPairs] = useState([]);

  // State to hold the update message
  const [updateMessage, setUpdateMessage] = useState("");

  // Add state variables for session name and start time
  const [sessionName, setSessionName] = useState("----");
  const [sessionStartTime, setSessionStartTime] = useState("----");

  // State to hold fetched data
  const [userMatchedData, setUserMatchedData] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("number");

  // Save userInfo to session storage when it changes
  useEffect(() => {
    if (userInfo) {
      sessionStorage.setItem("userInfo", JSON.stringify(userInfo));
      log.info("sessionStorage successful");
    }
  }, [userInfo]);

  // Function to display a message and remove it after 3 seconds
  function displayTemporaryMessage(message) {
    setUpdateMessage(message);
    setTimeout(() => {
      setUpdateMessage("");
    }, 3000);
  }

  // Retrieve userInfo from session storage on component mount
  const storedUserInfo = JSON.parse(sessionStorage.getItem("userInfo") || "{}");
  const nation =
    userInfo?.user?.nation || storedUserInfo?.user?.nation || "defaultNation";
  const userNameJP = userInfo?.user
    ? `${userInfo.user.family_name} ${userInfo.user.first_name} (${userInfo.user.family_name_kana} ${userInfo.user.first_name_kana})`
    : storedUserInfo?.user
    ? `${storedUserInfo.user.family_name} ${storedUserInfo.user.first_name} (${storedUserInfo.user.family_name_kana} ${storedUserInfo.user.first_name_kana})`
    : "defaultUserNameJP";
  const userNameVN = userInfo?.user
    ? `${userInfo.user.family_name} ${userInfo.user.first_name} (${userInfo.user.first_name_kana})`
    : storedUserInfo?.user
    ? `${storedUserInfo.user.family_name} ${storedUserInfo.user.first_name} (${storedUserInfo.user.first_name_kana})`
    : "defaultUserNameVN";
  const userEmail =
    userInfo?.user?.email || storedUserInfo?.user?.email || "defautl@gmail.com";

  // Redirect to root if default values are used
  useEffect(() => {
    // Check for default values and update state
    if (
      userNameJP === "defaultUserNameJP" ||
      userNameVN === "defaultUserNameVN" ||
      userEmail === "default@gmail.com"
    ) {
      setCanStartMeeting(false);
      navigate("/");
    } else {
      setCanStartMeeting(true);
    }
  }, []);

  let userName;
  if (nation === "Japan") {
    userName = userNameJP;
  } else {
    userName = userNameVN;
  }

  const authEndpoint = `${apiDomain}/auth-zoom`;
  const sdkKey = "y7quhxLnRrSAjCsADDlLXg";
  const meetingNumber = "84249023110";
  // const meetingNumber = "87887970670"
  const passWord = "";
  const role = userInfo?.user?.user_roles[0]?.role_name || 0;
  const registrantToken = "";
  const zakToken = "";
  const leaveUrl = `${leave_Url}`;

 // Function to get the signature for Zoom Meeting SDK
  const getSignature = async () => {
    setLoading(true);
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.idToken;
      log.info("Fetched session successfully", session);
      const req = await fetch(authEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          meetingNumber: meetingNumber,
          role: role,
        }),
      });
      const res = await req.json();
      const signature = res.signature;
      startMeeting(signature);
    } catch (e) {
      log.error("Error fetching signature:", e);
    } finally {
      setLoading(false);
    }
  };

  const mapUser = async (sessionUser) => {
    // Fetch authentication session and token
    const session = await fetchAuthSession();
    const token = session.tokens.idToken; // Ensure this is the correct path to the JWT token

    // Extract userId and persistentID from sessionUser
    const { persistentID } = sessionUser?.result?.currentUser;

    const zoomUserInfo = {
      zoom_id: persistentID,
    };

    // Function to send user attributes to the server with retry logic
    const sendUserAttributes = async (attempt = 1) => {
        try {
            const response = await axios.post(
                `${apiDomain}/usermap`,
                {
                    ...zoomUserInfo,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            // Check if the response is successful
            if (response.status === 200) {
                console.log("User attributes updated successfully.");
            } else {
                throw new Error("Failed to update user attributes.");
            }
        } catch (error) {
            console.error(`Attempt ${attempt}: Error sending user attributes:`, error);
            if (attempt < 4) {
                console.log("Retrying...");
                await sendUserAttributes(attempt + 1); // Retry the request
            } else {
                console.error("Failed to update user attributes after 3 attempts.");
                // Call leave function to sign out the user
                ZoomMtg.leaveMeeting({
                    confirm: false, // Consider changing to true for user confirmation
                    success: () => {
                        console.log("Successfully left the meeting due to failed attempts.");
                    },
                    error: (err) => {
                        console.error("Error leaving the meeting:", err);
                        // Suggestion: Provide user feedback instead of just navigating
                        alert("An error occurred while leaving the meeting. Please try again.");
                        navigate("/"); // Consider a more user-friendly navigation
                    },
                });
            }
        }
    };

    await sendUserAttributes();
  };

  // Function to start the Zoom meeting
  const startMeeting = async (signature) => {
    ZoomMtg.setZoomJSLib("https://source.zoom.us/3.11.0/lib", "/av");
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();
    // Load the Japanese language pack
    ZoomMtg.i18n.load("jp-JP");

    document.getElementById("zmmtg-root").style.display = "block";
    ZoomMtg.init({
      leaveUrl: leaveUrl,
      isSupportAV: true,
      enableHD: false,
      enableFullHD: false,
      patchJsMedia: true,
      leaveOnPageUnload: false,
      success: (success) => {
        ZoomMtg.join({
          sdkKey: sdkKey,
          signature: signature, // role in SDK signature needs to be 0
          meetingNumber: meetingNumber,
          userEmail: userEmail,
          passWord: passWord,
          userName: userName,
          success: (joinSuccess) => {
            log.info("The meeting is created successfully: ", joinSuccess);

            ZoomMtg.mirrorVideo({
              mirrored: true,
              success: (joinSuccess) => {
                log.info("Mirror video successful ", joinSuccess);
              },
              error: (error) => {
                log.info("Error occur when mirror video ", error);
              }
            });

            try {
              ZoomMtg.getCurrentUser({
                success: (res) => {
                  // console.log("Current User Info:", res);
                  // Mapping zoom user id and user id of the system
                  if (res?.result?.currentUser?.persistentID) {
                    mapUser(res);
                  }
                },
                error: (err) => {
                  log.error("Error getting current user:", err);
                },
              });
            } catch (error) {
              log.error("Unexpected error in getCurrentUser:", error);
            }
          },
          error: (error) => {
            log.error("Error joining the meeting:", error);
          },
        });

      },
      error: (error) => {
        log.error("Error initializing Zoom SDK:", error);
      },
    });
  };

  useEffect(() => {
    if (canStartMeeting) {
      try {
        getSignature();
      } catch (error) {
        navigate("/");
      }
    }
  }, [canStartMeeting]);

  useEffect(() => {
    const handlePopState = (event) => {
      const confirmationMessage = "ミーティングに退出します？";
      if (window.confirm(confirmationMessage)) {
        // Use ZoomMtg.leaveMeeting to leave the meeting
        // Use ZoomMtg.leaveMeeting to leave the meeting
        try {
          ZoomMtg.leaveMeeting({
            confirm: false, // Set to false to leave directly without additional confirmation
            success: () => {
              console.log("Successfully left the meeting.");
            },
            error: (err) => {
              console.error("Error leaving the meeting:", err);
              navigate("/"); // Navigate to root if there's an error
            },
          });
        } catch (err) {
          console.error("Unexpected error leaving the meeting:", err);
          navigate("/"); // Navigate to root if there's an unexpected error
        }
      } else {
        // Prevent navigation
        window.location.reload();
        window.history.pushState(null, null, window.location.pathname);
      }
    };

    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const handleAdminButtonClick = () => {
    setShowModal((prevShowModal) => !prevShowModal);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  // Function to fetch attendees list
  const fetchAttendeesList = () => {
    ZoomMtg.getAttendeeslist({
      success: (res) => {
        console.log("Attendees List:", res);
        setAttendees(res.result.attendeesList);
      },
      error: (err) => {
        console.error("Error getting attendees list:", err);
        alert("Failed to get attendees list.");
      },
    });
  };

  const fetchPairList = () => {
    try {
      ZoomMtg.getBreakoutRooms({
        success: (res) => {
          // console.log("res: ", res);
          const breakoutRooms = res.result.rooms;
          const roomParticipants = breakoutRooms
            .filter((room) => room.participants.length > 0) // Only include rooms with participants
            .map((room) => {
              return room.participants.reduce((acc, participant, index) => {
                const otherParticipant =
                  room.participants[(index + 1) % room.participants.length];
                acc[
                  participant.displayName
                ] = `${otherParticipant.displayName}, ${room.name}`;
                return acc;
              }, {});
            });

          // Merge all objects in roomParticipants into a single object
          const mergedRoomParticipants = roomParticipants.reduce(
            (acc, curr) => {
              return { ...acc, ...curr };
            },
            {}
          );

          // Sort the merged object by keys
          const sortedMergedRoomParticipants = Object.keys(
            mergedRoomParticipants
          )
            .sort()
            .reduce((acc, key) => {
              acc[key] = mergedRoomParticipants[key];
              return acc;
            }, {});
          // Convert the merged object back to an array with a single element
          const finalResult = [sortedMergedRoomParticipants];

          // Update your component state with the roomParticipants data
          setPairs(finalResult);

          // Export the roomParticipants data
        },
        error: (err) => {
          console.error("Error fetching breakout room data:", err);
        },
      });
    } catch (error) {
      console.error("Error executing getBreakoutRooms:", error);
    }
  };

  async function fetchBreakRoom() {
    try {
      return new Promise((resolve, reject) => {
        ZoomMtg.getBreakoutRooms({
          success: (res) => {
            const rooms = res.result.rooms;
            const unassigned = res.result.unassigned;
            console.log("getBreakoutRooms", res.result);
            resolve({ rooms, unassigned });
          },
          error: (err) => {
            console.error("Error fetching breakout rooms:", err);
            reject([]);
          },
        });
      });
    } catch (error) {
      console.error("Unexpected error executing getBreakoutRooms:", error);
      return [];
    }
  }

  async function userMatchingHistory() {
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.idToken;
      const { rooms } = await fetchBreakRoom();
      // Get the room have participants only
      const breakroomUser = rooms.filter(
        (room) => room.participants.length > 0
      );

      console.log("breakroomUserId", breakroomUser);

      const matchEndpoint = `${apiDomain}/match-history`;

      const response = await fetch(matchEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          breakroomUser: breakroomUser,
          sessionID: sessionName,
        }),
      });

      if (response.ok) {
        displayTemporaryMessage("履歴が正常に更新されました。"); // Success message
      } else {
        displayTemporaryMessage("履歴の更新に失敗しました。"); // Failure message
      }
    } catch (error) {
      console.error("Error in userMatchingHistory:", error);
      displayTemporaryMessage("履歴の更新中にエラーが発生しました。"); // Error message
    }
  }

  async function fetchSessionInfo() {
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.idToken;

      const matchEndpoint = `${apiDomain}/session-info`;

      const response = await fetch(matchEndpoint, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("response: ", response);

      if (response.ok) {
        const data = await response.json(); // Parse the JSON body
        console.log("data: ", data);
        if (data != null) {
          displayTemporaryMessage("セッション情報が正常に取得できました"); // Success message
        } else {
          displayTemporaryMessage(
            "セッションが存在しません。新しセッションを作成してください"
          ); // Success message
        }

        setSessionName(data?.session_name || "----"); // Access the parsed data
        setSessionStartTime(data?.session_start_time || "----"); // Access the parsed data
      } else {
        displayTemporaryMessage("エラーが発生しました"); // Failure message
      }
    } catch (error) {
      console.error("Error in userMatchingHistory:", error);
      displayTemporaryMessage("エラーが発生しました"); // Error message
    }
  }

  async function endSession() {
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.idToken;

      const matchEndpoint = `${apiDomain}/session-info`;

      const response = await fetch(matchEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          action: "endSession",
          sessionId: sessionName,
        }),
      });

      console.log("response: ", response);

      if (response.ok) {
        const data = await response.json(); // Parse the JSON body
        console.log("data: ", data);
        if (data == null) {
          displayTemporaryMessage("セッションが正常に終了できました"); // Success message
        }

        setSessionName(data?.session_name || "----"); // Access the parsed data
        setSessionStartTime(data?.session_start_time || "----"); // Access the parsed data
      } else {
        displayTemporaryMessage("エラーが発生しました"); // Failure message
      }
    } catch (error) {
      console.error("Error in endSession:", error);
      displayTemporaryMessage("エラーが発生しました"); // Error message
    }
  }

  async function createSession() {
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.idToken;

      const matchEndpoint = `${apiDomain}/session-info`;

      const response = await fetch(matchEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          action: "createSession",
        }),
      });

      console.log("response: ", response);

      if (response.ok) {
        const data = await response.json(); // Parse the JSON body
        console.log("data: ", data);
        if (data != null) {
          displayTemporaryMessage("セッションが正常に作成できました"); // Success message
        }

        setSessionName(data?.session_name || "----"); // Access the parsed data
        setSessionStartTime(data?.session_start_time || "----"); // Access the parsed data
      } else {
        displayTemporaryMessage("エラーが発生しました"); // Failure message
      }
    } catch (error) {
      console.error("Error in createSession:", error);
      displayTemporaryMessage("エラーが発生しました"); // Error message
    }
  }

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const sortedData = userMatchedData.sort((a, b) => {
    const valueA = a[orderBy];
    const valueB = b[orderBy];

    // Determine the sort order
    if (order === "asc") {
        if (typeof valueA === "number" && typeof valueB === "number") {
            return valueA - valueB; // Numeric comparison
        } else {
            return valueA > valueB ? 1 : -1; // String comparison
        }
    } else {
        if (typeof valueA === "number" && typeof valueB === "number") {
            return valueB - valueA; // Numeric comparison
        } else {
            return valueB > valueA ? 1 : -1; // String comparison
        }
    }
  });

  // const [showTable, setShowTable] = useState(false); // New state to control table display
  const [showMatchTable, setShowMatchTable] = useState(false); // New state to control table display
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Hide message after 2 seconds
  };

  // State to manage zoom_id inputs
  const [zoomIds, setZoomIds] = useState([""]); // Start with one empty input

  // Function to add a new input field
  const addZoomIdInput = () => {
    setZoomIds([...zoomIds, ""]); // Add a new empty input field
  };

  // Define the function outside of the onClick handler
  const handleGetMatchUserClick = async () => {
    const historyData = await fetchUserMatchedHistory();
    console.log("Updated User Matched Data:", historyData);
    return historyData
    // }
  };

  const exampleFormattedData = [
    {
      number: 1,
      roomId: "Room_001",
      user1Level: "Level1",
      userName1: "John Doe",
      zoom_id_1: "123456789",
      zoom_id_2: "987654321",
      userName2: "Jane Smith",
      user2Level: "Level2",
    },
    {
      number: 2,
      roomId: "Room_002",
      user1Level: "Level3",
      userName1: "Alice Johnson",
      zoom_id_1: "456789123",
      zoom_id_2: "654321987",
      userName2: "Bob Brown",
      user2Level: "Level4",
    },
    // Add more example entries as needed
  ];

  const [matchUserData, setMatchUserData] = useState([]); // New state for match user data

  const getCurrentParticipantList = async () =>{
    ZoomMtg.setZoomJSLib("https://source.zoom.us/3.11.0/lib", "/av");
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();
    try {
      const { rooms, unassigned } = await fetchBreakRoom();

      // // Assuming 'data' is the object containing the 'rooms' and 'unassigned' properties
      // const unassignUserList = unassigned.map(
      //   (participant) => participant.persistentID
      // );

      const emptyRoomList = rooms
        .filter((room) => room.participants.length === 0)
        .map((room) => room); // Return a list of empty room names

      // Get the room have participants only
      const breakroomUserList = rooms.filter(
        (room) => room.participants.length > 0
      );

      let unassignUserList = unassigned;

      console.log("breakroomUserList", breakroomUserList);
      console.log("unassignUserList", unassignUserList);

      return {breakroomUserList, unassignUserList};
    }
    catch(error){
      console.log("Error when get a list of current participant: ", error)
    }
  }

  const fetchUserMatchedHistory = async () => {
    try {
        const session = await fetchAuthSession();
        const token = session.tokens.idToken;

        const matchEndpoint = `${apiDomain}/user-matched-history`;

        const { breakroomUserList, unassignUserList } = await getCurrentParticipantList();
        console.log("breakroomUserList", breakroomUserList);
        console.log("unassignUserList", unassignUserList);

        const res = await fetch(matchEndpoint, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                breakroomUser: breakroomUserList,
                unassigned: unassignUserList,
                sessionID: sessionName,
            }),
        });
        console.log("res: ", res)

        if (res.ok) {
            const response = await res.json();
            console.log("response: ", response);

            // Check if response.body is defined and is an object
            if (response && typeof response === 'object') {
                const participantMap = {};

                // Create a mapping for breakroomUserList
                breakroomUserList.forEach(room => {
                    room.participants.forEach(user => {
                        participantMap[user.persistentID] = user.displayName;
                    });
                });

                // Create a mapping for unassignUserList
                unassignUserList.forEach(user => {
                    participantMap[user.persistentID] = user.displayName;
                });

                console.log("participantMap:", participantMap);

                // Format the data
                const formattedData = Object.entries(response)
                    .filter(([key]) => key !== "missing_participant_ids") // Skip the missing_participant_ids key
                    .map(([id, data]) => {
                        // const sessionId2 = data.matched_history[1] ? participantMap[data.matched_history[1]] : "N/A"; // Check for existence
                        const matchedHistory = data.matched_history;

                        // Sort sessions by session ID
                        const sortedSessions = Object.entries(matchedHistory)
                            .sort(([a], [b]) => a.localeCompare(b));

                        // console.log("sortedSessions: ", sortedSessions)

                        // Map session participants to display names
                        const sessionMapping = sortedSessions.map(([sessionId, participants]) => {
                            const participantNames = participants.map(pid => {
                                return pid && participantMap[pid] ? participantMap[pid] : "N/A";
                            });
                            return { sessionId, participantNames: participantNames.join(", ") };
                        });

                        // console.log("sessionMapping: ", sessionMapping)

                        return {
                            zoom_id: id, 
                            scores: data.score,
                            zoom_name: participantMap[id] || `User ${id}`, // Use participant name or fallback to default
                            scores: data.score,
                            session_id1: sessionMapping[0]?.participantNames || "N/A", // Map to participant name
                            session_id2: sessionMapping[1]?.participantNames || "N/A", // Map to participant name
                            session_id1_name: sessionMapping[0]?.sessionId || "SecondLastest",
                            session_id2_name: sessionMapping[1]?.sessionId || "Lastest",
                            nation: data.nation,
                            age: data.age, 
                            category: data.category, 
                            matched_history: data.matched_history
                        };
                    });

                setUserMatchedData(formattedData);
                return formattedData;
            } else {
                console.error("Response body is not valid:", response);
                displayTemporaryMessage("ユーザーマッチデータの取得に失敗しました。"); // Display error message
            }
        } else {
            console.error("Failed to fetch user matched data");
            displayTemporaryMessage("ユーザーマッチデータの取得に失敗しました。"); // Display error message
        }
    } catch (error) {
        console.error("Error fetching user matched data:", error);
        displayTemporaryMessage("ユーザーマッチデータの取得中にエラーが発生しました。"); // Display error message
    }
  };

  return (
    <>
      {loading && (
        <div className="loading-container">
          <ClipLoader />
        </div>
      )}
      <div
        id="zmmtg-root"
        style={{ display: "none", position: "relative" }}
      ></div>
      {role && (
        <button id="overlay-button" onClick={handleAdminButtonClick}>
          Admin
        </button>
      )}
      {showModal && (
        <div id="modal">
          <div id="session-info">
            <p>
              <span>セッション</span>
            </p>
            <p>
              現状セッション: <span>{sessionName}</span>
            </p>
            <p>
              開始時間: <span>{sessionStartTime}</span>
            </p>
            <button onClick={fetchSessionInfo}>更新</button>
            {/* <button id="close-session">セッション終了</button> */}
            {sessionName !== "----" ? (
              <button
                id="close-session"
                onClick={() => {
                  if (window.confirm("このセッションを終了しますか？")) {
                    endSession(); // Call the function if confirmed
                  }
                }}
              >
                セッション終了
              </button>
            ) : (
              <button
                id="create-session"
                onClick={() => {
                  if (window.confirm("新しいセッションを作成しますか？")) {
                    createSession(); // Call the function if confirmed
                  }
                }}
              >
                新しセッション作成
              </button>
            )}

            <button
              onClick={() => {
                if (
                  window.confirm(
                    "このセッションのユーザーのマッチ履歴を更新しますか？"
                  )
                ) {
                  userMatchingHistory(); // Call the function if confirmed
                }
              }}
            >
              ペア履歴更新
            </button>
          </div>

          <div className="message-container">
            <p>{updateMessage && <div>{updateMessage}</div>}</p>
          </div>

          <div id="attendees-list">
            <button id="attendees-button" onClick={fetchAttendeesList}>
              参加者一覧
            </button>
            <ol>
              {attendees.map((attendee) => (
                <li key={attendee.userId}>
                  {attendee.userId}: {attendee.userName}
                </li>
              ))}
            </ol>
          </div>

          <div id="attendees-pair-list">
            <button id="attendees-button" onClick={fetchPairList}>
              ペア一覧
            </button>
            {pairs.map((pair, index) => (
              <ol>
                {Object.entries(pair).map(([participant, details]) => (
                  <li key={participant}>
                    {participant}: {details}
                  </li>
                ))}
              </ol>
            ))}
          </div>

          <MatchedHistory
            userMatchedData={userMatchedData}
            orderBy={orderBy}
            order={order}
            handleRequestSort={handleRequestSort}
            handleCopy={handleCopy}
            copied={copied}
            handleGetMatchUserClick={handleGetMatchUserClick}
          />

          <ChangeBreakRoom
            userMatchedData={userMatchedData}
            orderBy={orderBy}
            order={order}
            handleRequestSort={handleRequestSort}
            handleCopy={handleCopy}
            copied={copied}
            handleGetMatchUserClick={handleGetMatchUserClick}
          />

          {/* <div id="attendees-pair-list">
            <button
              id="attendees-button"
              onClick={changeRoomForUserInBreakoutRoom}
            >
              切り替え
            </button>
          </div> */}
        </div>
      )}
    </>
  );
}

export default Meeting;