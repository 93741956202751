import React, { useState } from 'react';
import { Paper, Typography, Box, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel, CircularProgress } from "@mui/material";
import { CopyToClipboard } from 'react-copy-to-clipboard';

const MatchedHistory = ({
  userMatchedData,
  handleCopy,
  copied,
  handleGetMatchUserClick
}) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [orderBy, setOrderBy] = useState('number');
  const [order, setOrder] = useState('asc');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSwitchUserInBreakRoom = async () => {
    setLoading(true);
    setErrorMessage('');
    try {
      await handleGetMatchUserClick();
    } catch (error) {
      setErrorMessage('データの取得中にエラーが発生しました。');
      setTimeout(() => setErrorMessage(''), 2000);
    }
    setLoading(false);
  };

  // Function to sort data
  const sortedData = userMatchedData.slice().sort((a, b) => {
    if (orderBy === 'number') {
      return order === 'asc' ? a.number - b.number : b.number - a.number;
    } else {
      const aValue = a[orderBy] || '';
      const bValue = b[orderBy] || '';
      if (aValue < bValue) {
        return order === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return order === 'asc' ? 1 : -1;
      }
      return 0;
    }
  });

  // console.log("userMatchedData1: ",userMatchedData)

  return (
    <Paper elevation={3} sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "10px", borderRadius: "8px",minWidth: "95vw", minHeight: "850px", marginLeft: "30px",marginRight: "30px", overflow: "auto" }}>
      <Typography variant="h6" gutterBottom sx={{ marginTop: '16px', marginBottom: '16px' }}>
        <strong>Matched History</strong>
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'left', width: '95%', backgroundColor: 'lightgrey', padding: '10px' }}>

        <Box sx={{display:'flex', justifyContent:'left', marginBottom: "20px",marginTop:"10px", marginLeft:"15px"}}>
          <Box sx={{ display: 'flex', alignItems: 'center', marginRight: "50px" }}>
            <Button
              variant="contained"
              onClick={handleSwitchUserInBreakRoom}
            >
              ペア履歴
            </Button>

            {loading && (
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', marginLeft: '40px' }}>
                <CircularProgress size={30} />
                <Typography variant="body1" sx={{ marginLeft: '10px' }}>
                  ...更新中...
                </Typography>
              </Box>
            )}

            {errorMessage && (
              <Typography variant="body1" color="error" sx={{ marginLeft: '20px' }}>
                {errorMessage}
              </Typography>
            )}
          </Box>
        </Box>
 
        {userMatchedData.length > 0 ? (
            <TableContainer component={Paper} sx={{ display: 'block', width: '100%', maxHeight: '600px', overflow: 'auto' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "number"}
                        direction={orderBy === "number" ? order : "asc"}
                        onClick={() => handleRequestSort("number")}
                      >
                        Number
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "zoom_id"}
                        direction={orderBy === "zoom_id" ? order : "asc"}
                        onClick={() => handleRequestSort("zoom_id")}
                      >
                        Zoom ID
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "nation"}
                        direction={orderBy === "nation" ? order : "asc"}
                        onClick={() => handleRequestSort("nation")}
                      >
                        Nation
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "age"}
                        direction={orderBy === "age" ? order : "asc"}
                        onClick={() => handleRequestSort("age")}
                      >
                        Age
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "category"}
                        direction={orderBy === "category" ? order : "asc"}
                        onClick={() => handleRequestSort("category")}
                      >
                        Category
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "scores"}
                        direction={orderBy === "scores" ? order : "asc"}
                        onClick={() => handleRequestSort("scores")}
                      >
                        Scores
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={orderBy === "zoom_name"}
                        direction={orderBy === "zoom_name" ? order : "asc"}
                        onClick={() => handleRequestSort("zoom_name")}
                      >
                        Zoom Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>{userMatchedData[0]?.session_id1_name || "Session ID1"}</TableCell>
                    <TableCell>{userMatchedData[0]?.session_id2_name || "Session ID2"}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {item.zoom_id}
                        <CopyToClipboard
                          text={item.zoom_id}
                          onCopy={handleCopy}
                        >
                          <Button>
                            <i className="fas fa-copy gray-icon"></i>
                          </Button>
                        </CopyToClipboard>
                      </TableCell>
                      <TableCell>{item.nation}</TableCell>
                      <TableCell>{item.age}</TableCell>
                      <TableCell>{item.category}</TableCell>
                      <TableCell>{item.scores}</TableCell>
                      <TableCell>{item.zoom_name}</TableCell>
                      <TableCell>{item.session_id1}</TableCell>
                      <TableCell>{item.session_id2}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
        ) : ( null
        )}
      </Box>
      {copied && (
        <div className="copy-message">Zoom ID copied to clipboard!</div>
      )}
    </Paper>
  );
};

export default MatchedHistory;