import React, { useState, useEffect } from "react";
import { Amplify} from 'aws-amplify';
import { Hub } from '@aws-amplify/core';
import { signIn, signInWithRedirect, getCurrentUser, resendSignUpCode } from '@aws-amplify/auth';
import { useNavigate, Link } from 'react-router-dom';
import { ClipLoader } from "react-spinners";
import "./Login.css";
import "./Signup.css";
import awsconfig from '../../aws-exports';
import useSubmitButton from '../../hooks/useSubmitButton';
import { getErrorMessage } from '../../utils/errorMessages';
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import eye icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

// Configure Amplify
Amplify.configure(awsconfig);

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [isGoogleLoading, setIsGoogleLoading] = useState(false);
  const [isFacebookLoading, setIsFacebookLoading] = useState(false);
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const checkAuthStatus = async () => {
    try {
      const user = await getCurrentUser();
      // console.log("ユーザー情報:", user);
      if (user) {
        // User is already logged in, redirect to home page
        navigate('/');
      }
    } catch (error) {
      // User is not logged in, do nothing (stay on login page)
      console.log("User is not logged in");
    }
  };

  const checkRedirectStatus = async () => {
    try {
      const result = await getCurrentUser();
      if (result) {
        // User has successfully signed in after redirect
        navigate('/');
      }
    } catch (error) {
      // No redirect sign-in occurred
      console.log("No redirect sign-in detected");
    } finally {
      setIsGoogleLoading(false);
      setIsFacebookLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    setIsGoogleLoading(true);
    try {
      await signInWithRedirect({ provider: 'Google' });
      // The page will redirect, so we won't reach this point
    } catch (error) {
      console.error('Googleでのログインエラー', error);
      setIsGoogleLoading(false);
      throw new Error(getErrorMessage(error));
    }
  };

  const handleFacebookSignIn = async () => {
    setIsFacebookLoading(true);
    try {
      await signInWithRedirect({ provider: 'Facebook' });
      // The page will redirect, so we won't reach this point
    } catch (error) {
      console.error('Facebookでのログインエラー', error);
      setIsFacebookLoading(false);
      throw new Error(getErrorMessage(error));
    }
  };

  const handleResendAndConfirm = async (email, password) => {
    try {
      const result = await resendSignUpCode({ username: email });
      // console.log("確認コードの再送信結果:", result);
      
      if (result.destination) {
        console.log("確認コードが再送信されました");
        navigate('/confirm-signup', { 
          state: { 
            email, 
            password,
            message: '新しい確認コードが送信されました。メールをご確認ください。'
          } 
        });
      } else {
        console.error('確認コードの再送信に失敗しました');
        setError(getErrorMessage('RESEND_CODE_FAILED'));
      }
    } catch (resendError) {
      console.error('確認コードの再送信エラー', resendError);
      setError(getErrorMessage(resendError.name || resendError.message || 'RESEND_CODE_ERROR'));
    }
  };

  const submitAction = async () => {
    try {
      const signInResult = await signIn({
        username: email,
        password: password
      });
      
      if (signInResult.isSignedIn) {
        console.log("ログイン成功済");
        navigate('/');
      } else if (signInResult.nextStep && signInResult.nextStep.signInStep === 'CONFIRM_SIGN_UP') {
        // console.log("アカウント確認が必要:", signInResult.nextStep);
        await handleResendAndConfirm(email, password);
      } 
      else {
        console.error('ログイン失敗: 予期せぬ応答', signInResult);
        setError(getErrorMessage('UNEXPECTED_SIGN_IN_RESPONSE'));
      }
    } catch (error) {
      console.error('ログインエラー', error);
      
      if (error.name === 'UserNotConfirmedException') {
        await handleResendAndConfirm(email, password); 
      }
      else if (error.name === 'NotAuthorizedException') {
        setError(getErrorMessage('INCORRECT_PASSWORD'));
      }
      else if (error.name === 'UserNotFoundException') {
        console.error('ログインエラー UserNotFoundException: User does not exist.');
        setError(getErrorMessage('USER_NOT_FOUND')); // Set the error message for user not found
      } else {
        setError(getErrorMessage(error.name || error.message || 'DEFAULT'));
      }
    }
  };

  const { isLoading, error: handleSubmitError, handleSubmit } = useSubmitButton(submitAction);

  return (
    <div className={`login-page ${isLoading || isGoogleLoading || isFacebookLoading ? 'loading' : ''}`}>
      <div className="login_form">
        <div onClick={() => navigate(-1)} className="move-back-button" style={{ cursor: 'pointer' }}>
          <FontAwesomeIcon icon={faArrowLeft} /> 戻る
        </div>
        <form onSubmit={handleSubmit}>
          <h2>友達さん会員ログイン</h2>

          <div className="login_option">
            <button 
              type="button" 
              onClick={handleGoogleSignIn} 
              className="social-login-button google-button" 
              disabled={isGoogleLoading || isFacebookLoading}
            >
              <i className="fab fa-google"></i>
              <span className="social-media-space">Googleでログイン</span>
            </button>
            {/* <button 
              type="button" 
              onClick={handleFacebookSignIn} 
              className="social-login-button facebook-button" 
              disabled={isGoogleLoading || isFacebookLoading}
            >
              <i className="fab fa-facebook"></i>
              <span className="social-media-space">Facebookでログイン</span>
            </button> */}
          </div>
          <p className="separator">
            <span>または</span>
          </p>
          <div className="input_box">
            <label htmlFor="email">メールアドレス</label>
            <input
              type="email"
              id="email"
              placeholder="メールアドレスを入力してください"
              required
              value={email}
              autoComplete="username"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input_box">
            <div className="password_title">
              <label htmlFor="password">パスワード</label>
              <Link to="/reset-password" className="forgot-password">パスワードを忘れた方</Link>
            </div>
            <div className="password-input-eye-container">
                <div className="passwordinput">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    placeholder="パスワードを入力してください"
                    required
                    value={password}
                    autoComplete="current-password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                <div className="showpassword">
                  {showPassword ? (
                    <FaEyeSlash onClick={togglePasswordVisibility} />
                  ) : (
                    <FaEye onClick={togglePasswordVisibility} />
                  )}
                </div>

            </div>
            {/* <input
              type="password"
              id="password"
              placeholder="パスワードを入力"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            /> */}
          </div>
          {handleSubmitError && <div className="error-message">{handleSubmitError}</div>}
          {error && <div className="error-message">{error}</div>} {/* Display auth error */}
          <button type="submit" disabled={isLoading}>
            {isLoading ? 'ログイン中...' : 'ログイン'}
          </button>

          <button type="button" className="transit-login-signup" onClick={() => navigate('/signup')}>
            新規登録
          </button>

        </form>
      </div>
      {(isLoading || isGoogleLoading || isFacebookLoading) && (
        <div className="loading-overlay">
          <ClipLoader className="spinner" loading={true} />
        </div>
      )}
    </div>
  );
}

export default Login;
