import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer>
      <p>&copy; 2024 tomodachisan</p>
      <div id="footer_link">
        <div>
          <Link to="/login" className="link-button">ログイン</Link>
          <Link to="/guideline" className="link-button">案内</Link>
        </div>
        <div>
          <Link to="https://www.facebook.com/groups/tomodachisan" className="link-button">Facebook</Link>
          <Link to="https://docs.google.com/forms/d/e/1FAIpQLSfF4QvOBYRP8a76Cb6GCl5QKeTJh6HpNl6G54N4l8ieJ7xN3Q/viewform" className="link-button">お問い合わせ</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;