import React, { useState, useEffect } from "react";
import "./Header.css";
import { useNavigate, Link } from "react-router-dom";
import { getCurrentUser, signOut } from "@aws-amplify/auth";
import { FaUserCircle } from "react-icons/fa";

const Header = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const checkUserStatus = async () => {
      try {
        const user = await getCurrentUser();
        setIsLoggedIn(!!user);
      } catch {
        setIsLoggedIn(false);
      }
    };

    checkUserStatus();
  }, []);

  const handleSignup = () => {
    navigate("/signup");
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const handleLogout = async () => {
    try {
      await signOut();
      setIsLoggedIn(false);
      navigate("/");
    } catch (error) {
      console.error("Logout error", error);
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <>
      <header>
        <div id="logo">
          <h1
            className="logo_adjust"
            onClick={() => navigate("/")}
            style={{ cursor: "pointer" }}
          >
            友達さん
          </h1>
        </div>



        <div className="header-buttons">
          {isLoggedIn ? (
            <div id="logged-in-header">
                <div id="event_guidelines">
                  <Link to="/guideline">案内</Link>
                </div>
              <div className="user-menu">
                <FaUserCircle size={40} className="user-icon" />
                <div className="dropdown-content">
                  <button onClick={handleLogout}>ログアウト</button>
                </div>
              </div>

            </div>
          ) : (
            <>
              <section id="account">
                <div id="event_guidelines">
                  <Link to="/guideline">案内</Link>
                </div>
                <div>
                  <button id="create_btn" onClick={() => navigate("/signup")}>
                    アカウント作成
                  </button>
                </div>
                <div>
                  <button id="login_btn" onClick={() => navigate("/login")}>
                    ログイン
                  </button>
                </div>
              </section>
              <section className="hamburger-menu-container">
                <div className="hamburger-menu" onClick={toggleMenu}>
                  <div className="line"></div>
                  <div className="line"></div>
                  <div className="line"></div>
                </div>
                <div id="menu" className={`menu ${menuOpen ? "open" : ""}`}>
                  <button className="close-menu" onClick={closeMenu}>
                    ×
                  </button>
                  <ul>
                    <li>
                      <Link to="/login" className="link-button">
                        ログイン
                      </Link>
                    </li>
                    <li>
                      <Link to="/signup" className="link-button">
                        アカウント作成
                      </Link>
                    </li>
                    <li>
                      <Link to="/guideline" className="link-button">
                        案内
                      </Link>
                    </li>
                  </ul>
                </div>
              </section>
            </>
          )}
        </div>
      </header>
    </>
  );
};

export default Header;
