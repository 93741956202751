import React, { useState } from "react";
import { Amplify } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from "react-spinners";
import "./Login.css"; // Assumed path to your CSS file
import { resetPassword } from 'aws-amplify/auth';
import awsconfig from '../../aws-exports';
import useSubmitButton from '../../hooks/useSubmitButton';
import { getErrorMessage } from '../../utils/errorMessages';
import { resendSignUpCode } from 'aws-amplify/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

// Configure Amplify
Amplify.configure(awsconfig);

function Resetpassword() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const submitAction = async () => {
    try {
      const output = await resetPassword({
        username: email
      });
      const { nextStep } = output;
      if (nextStep.resetPasswordStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE') {
        // Redirect to the confirm-new-password page with the email
        navigate('/confirm-new-password', { state: { email } });
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      throw new Error(getErrorMessage(error));
    }
  };

  const { isLoading, error, handleSubmit } = useSubmitButton(submitAction);

  const handleResendCode = async () => {
    if (!email) {
      throw new Error(getErrorMessage('INVALID_EMAIL'));
    }

    try {
      await resendSignUpCode({ username: email });
      // console.log('確認コードが再送信されました');
      // Show a success message to the user
    } catch (error) {
      console.error('確認コードの再送信エラー', error);
      throw new Error(getErrorMessage(error));
    }
  };

  return (
    <div className={`login-page ${isLoading ? 'loading' : ''}`}>
      <div className="login_form">
        <div onClick={() => navigate(-1)} className="move-back-button" style={{ cursor: 'pointer' }}>
          <FontAwesomeIcon icon={faArrowLeft} /> 戻る
        </div>
        <form onSubmit={handleSubmit}>
          <h2>パスワード再設定</h2>
          <p className="resetpass-explain">
            パスワード再設定用のメールを送信します。友達さん会員に登録されたメールアドレスを入力してください
          </p>
          <div className="input_box">
            <label htmlFor="email">メールアドレス</label>
            <input
              type="email"
              id="email"
              placeholder="メールアドレスを入力"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          {error && <div className="error-message">{error}</div>}

          <button type="submit" disabled={isLoading}>
            {isLoading ? 'メール送信中...' : 'メールを送信する'}
          </button>
        </form>
      </div>
      {isLoading && (
        <div className="loading-overlay">
          <ClipLoader className="spinner" loading={isLoading} />
        </div>
      )}
    </div>
  );
}

export default Resetpassword;
